export const Welcome = () =>{
    return (
        <p className="lead mb-0">
				<span
					className="btn-lg btn-light fw-bold border-white bg-white text-color-434343"
					style={{ fontSize: "26px", fontWeight: "700" }}
				>
					Welcome to
				</span>
			</p>
    )
}