import { Switch } from "antd";
import React, { useState } from "react";
import { TwoFAEnableDisable } from "../apiservice/ApiService";
import { Loader } from "../common/Loader";
import { toast, ToastContainer } from "react-toastify";

const TwoFactorSwitcher = ({ enable, userId, fetchUserListing }) => {
  const [twoFactor, setTwoFactor] = useState(enable);
  const [loading, setLoading] = useState(false);

  const handleChange = async (value) => {
    setTwoFactor(value);
    setLoading(true);
    let payload = JSON.stringify({
      is_2fa_enabled: value,
      user_id: userId,
    });
    try {
      const res = await TwoFAEnableDisable(payload);
      if(res.success) {
        await fetchUserListing();
        toast.success(res.message + ' For This User.');
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      console.log("error", error);
      toast.error('Something went wrong!');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Loader />}
      <Switch checked={twoFactor} onChange={(e) => handleChange(e)} />
      {/* <ToastContainer position="bottom-right" /> */}
    </>
  );
};

export default TwoFactorSwitcher;
