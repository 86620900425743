export const countries = [
    { countryname: "Afghanistan", countrycode: "AF" },
    { countryname: "Albania", countrycode: "AL" },
    { countryname: "Algeria", countrycode: "DZ" },
    { countryname: "Andorra", countrycode: "AD" },
    { countryname: "Angola", countrycode: "AO" },
    { countryname: "Antigua and Barbuda", countrycode: "AG" },
    { countryname: "Argentina", countrycode: "AR" },
    { countryname: "Armenia", countrycode: "AM" },
    { countryname: "Australia", countrycode: "AU" },
    { countryname: "Austria", countrycode: "AT" },
    { countryname: "Azerbaijan", countrycode: "AZ" },
    { countryname: "Bahamas", countrycode: "BS" },
    { countryname: "Bahrain", countrycode: "BH" },
    { countryname: "Bangladesh", countrycode: "BD" },
    { countryname: "Barbados", countrycode: "BB" },
    { countryname: "Belarus", countrycode: "BY" },
    { countryname: "Belgium", countrycode: "BE" },
    { countryname: "Belize", countrycode: "BZ" },
    { countryname: "Benin", countrycode: "BJ" },
    { countryname: "Bhutan", countrycode: "BT" },
    { countryname: "Bolivia", countrycode: "BO" },
    { countryname: "Bosnia and Herzegovina", countrycode: "BA" },
    { countryname: "Botswana", countrycode: "BW" },
    { countryname: "Brazil", countrycode: "BR" },
    { countryname: "Brunei", countrycode: "BN" },
    { countryname: "Bulgaria", countrycode: "BG" },
    { countryname: "Burkina Faso", countrycode: "BF" },
    { countryname: "Burundi", countrycode: "BI" },
    { countryname: "Cabo Verde", countrycode: "CV" },
    { countryname: "Cambodia", countrycode: "KH" },
    { countryname: "Cameroon", countrycode: "CM" },
    { countryname: "Canada", countrycode: "CA" },
    { countryname: "Central African Republic", countrycode: "CF" },
    { countryname: "Chad", countrycode: "TD" },
    { countryname: "Chile", countrycode: "CL" },
    { countryname: "China", countrycode: "CN" },
    { countryname: "Colombia", countrycode: "CO" },
    { countryname: "Comoros", countrycode: "KM" },
    { countryname: "Congo (Congo-Brazzaville)", countrycode: "CG" },
    { countryname: "Costa Rica", countrycode: "CR" },
    { countryname: "Croatia", countrycode: "HR" },
    { countryname: "Cuba", countrycode: "CU" },
    { countryname: "Cyprus", countrycode: "CY" },
    { countryname: "Czechia (Czech Republic)", countrycode: "CZ" },
    { countryname: "Democratic Republic of the Congo", countrycode: "CD" },
    { countryname: "Denmark", countrycode: "DK" },
    { countryname: "Djibouti", countrycode: "DJ" },
    { countryname: "Dominica", countrycode: "DM" },
    { countryname: "Dominican Republic", countrycode: "DO" },
    { countryname: "Ecuador", countrycode: "EC" },
    { countryname: "Egypt", countrycode: "EG" },
    { countryname: "El Salvador", countrycode: "SV" },
    { countryname: "Equatorial Guinea", countrycode: "GQ" },
    { countryname: "Eritrea", countrycode: "ER" },
    { countryname: "Estonia", countrycode: "EE" },
    { countryname: "Eswatini (fmr. Swaziland)", countrycode: "SZ" },
    { countryname: "Ethiopia", countrycode: "ET" },
    { countryname: "Fiji", countrycode: "FJ" },
    { countryname: "Finland", countrycode: "FI" },
    { countryname: "France", countrycode: "FR" },
    { countryname: "Gabon", countrycode: "GA" },
    { countryname: "Gambia", countrycode: "GM" },
    { countryname: "Georgia", countrycode: "GE" },
    { countryname: "Germany", countrycode: "DE" },
    { countryname: "Ghana", countrycode: "GH" },
    { countryname: "Greece", countrycode: "GR" },
    { countryname: "Grenada", countrycode: "GD" },
    { countryname: "Guatemala", countrycode: "GT" },
    { countryname: "Guinea", countrycode: "GN" },
    { countryname: "Guinea-Bissau", countrycode: "GW" },
    { countryname: "Guyana", countrycode: "GY" },
    { countryname: "Haiti", countrycode: "HT" },
    { countryname: "Honduras", countrycode: "HN" },
    { countryname: "Hungary", countrycode: "HU" },
    { countryname: "Iceland", countrycode: "IS" },
    { countryname: "India", countrycode: "IN" },
    { countryname: "Indonesia", countrycode: "ID" },
    { countryname: "Iran", countrycode: "IR" },
    { countryname: "Iraq", countrycode: "IQ" },
    { countryname: "Ireland", countrycode: "IE" },
    { countryname: "Israel", countrycode: "IL" },
    { countryname: "Italy", countrycode: "IT" },
    { countryname: "Jamaica", countrycode: "JM" },
    { countryname: "Japan", countrycode: "JP" },
    { countryname: "Jordan", countrycode: "JO" },
    { countryname: "Kazakhstan", countrycode: "KZ" },
    { countryname: "Kenya", countrycode: "KE" },
    { countryname: "Kiribati", countrycode: "KI" },
    { countryname: "Kuwait", countrycode: "KW" },
    { countryname: "Kyrgyzstan", countrycode: "KG" },
    { countryname: "Laos", countrycode: "LA" },
    { countryname: "Latvia", countrycode: "LV" },
    { countryname: "Lebanon", countrycode: "LB" },
    { countryname: "Lesotho", countrycode: "LS" },
    { countryname: "Liberia", countrycode: "LR" },
    { countryname: "Libya", countrycode: "LY" },
    { countryname: "Liechtenstein", countrycode: "LI" },
    { countryname: "Lithuania", countrycode: "LT" },
    { countryname: "Luxembourg", countrycode: "LU" },
    { countryname: "Madagascar", countrycode: "MG" },
    { countryname: "Malawi", countrycode: "MW" },
    { countryname: "Malaysia", countrycode: "MY" },
    { countryname: "Maldives", countrycode: "MV" },
    { countryname: "Mali", countrycode: "ML" },
    { countryname: "Malta", countrycode: "MT" },
    { countryname: "Marshall Islands", countrycode: "MH" },
    { countryname: "Mauritania", countrycode: "MR" },
    { countryname: "Mauritius", countrycode: "MU" },
    { countryname: "Mexico", countrycode: "MX" },
    { countryname: "Micronesia", countrycode: "FM" },
    { countryname: "Moldova", countrycode: "MD" },
    { countryname: "Monaco", countrycode: "MC" },
    { countryname: "Mongolia", countrycode: "MN" },
    { countryname: "Montenegro", countrycode: "ME" },
    { countryname: "Morocco", countrycode: "MA" },
    { countryname: "Mozambique", countrycode: "MZ" },
    { countryname: "Myanmar (formerly Burma)", countrycode: "MM" },
    { countryname: "Namibia", countrycode: "NA" },
    { countryname: "Nauru", countrycode: "NR" },
    { countryname: "Nepal", countrycode: "NP" },
    { countryname: "Netherlands", countrycode: "NL" },
    { countryname: "New Zealand", countrycode: "NZ" },
    { countryname: "Nicaragua", countrycode: "NI" },
    { countryname: "Niger", countrycode: "NE" },
    { countryname: "Nigeria", countrycode: "NG" },
    { countryname: "North Korea", countrycode: "KP" },
    { countryname: "North Macedonia", countrycode: "MK" },
    { countryname: "Norway", countrycode: "NO" },
    { countryname: "Oman", countrycode: "OM" },
    { countryname: "Pakistan", countrycode: "PK" },
    { countryname: "Palau", countrycode: "PW" },
    { countryname: "Palestine State", countrycode: "PS" },
    { countryname: "Panama", countrycode: "PA" },
    { countryname: "Papua New Guinea", countrycode: "PG" },
    { countryname: "Paraguay", countrycode: "PY" },
    { countryname: "Peru", countrycode: "PE" },
    { countryname: "Philippines", countrycode: "PH" },
    { countryname: "Poland", countrycode: "PL" },
    { countryname: "Portugal", countrycode: "PT" },
    { countryname: "Qatar", countrycode: "QA" },
    { countryname: "Romania", countrycode: "RO" },
    { countryname: "Russia", countrycode: "RU" },
    { countryname: "Rwanda", countrycode: "RW" },
    { countryname: "Saint Kitts and Nevis", countrycode: "KN" },
    { countryname: "Saint Lucia", countrycode: "LC" },
    { countryname: "Saint Vincent and the Grenadines", countrycode: "VC" },
    { countryname: "Samoa", countrycode: "WS" },
    { countryname: "San Marino", countrycode: "SM" },
    { countryname: "Sao Tome and Principe", countrycode: "ST" },
    { countryname: "Saudi Arabia", countrycode: "SA" },
    { countryname: "Senegal", countrycode: "SN" },
    { countryname: "Serbia", countrycode: "RS" },
    { countryname: "Seychelles", countrycode: "SC" },
    { countryname: "Sierra Leone", countrycode: "SL" },
    { countryname: "Singapore", countrycode: "SG" },
    { countryname: "Slovakia", countrycode: "SK" },
    { countryname: "Slovenia", countrycode: "SI" },
    { countryname: "Solomon Islands", countrycode: "SB" },
    { countryname: "Somalia", countrycode: "SO" },
    { countryname: "South Africa", countrycode: "ZA" },
    { countryname: "South Korea", countrycode: "KR" },
    { countryname: "South Sudan", countrycode: "SS" },
    { countryname: "Spain", countrycode: "ES" },
    { countryname: "Sri Lanka", countrycode: "LK" },
    { countryname: "Sudan", countrycode: "SD" },
    { countryname: "Suriname", countrycode: "SR" },
    { countryname: "Sweden", countrycode: "SE" },
    { countryname: "Switzerland", countrycode: "CH" },
    { countryname: "Syria", countrycode: "SY" },
    { countryname: "Tajikistan", countrycode: "TJ" },
    { countryname: "Tanzania", countrycode: "TZ" },
    { countryname: "Thailand", countrycode: "TH" },
    { countryname: "Timor-Leste", countrycode: "TL" },
    { countryname: "Togo", countrycode: "TG" },
    { countryname: "Tonga", countrycode: "TO" },
    { countryname: "Trinidad and Tobago", countrycode: "TT" },
    { countryname: "Tunisia", countrycode: "TN" },
    { countryname: "Turkey", countrycode: "TR" },
    { countryname: "Turkmenistan", countrycode: "TM" },
    { countryname: "Tuvalu", countrycode: "TV" },
    { countryname: "Uganda", countrycode: "UG" },
    { countryname: "Ukraine", countrycode: "UA" },
    { countryname: "United Arab Emirates", countrycode: "AE" },
    { countryname: "United Kingdom", countrycode: "GB" },
    { countryname: "United States", countrycode: "US" },
    { countryname: "Uruguay", countrycode: "UY" },
    { countryname: "Uzbekistan", countrycode: "UZ" },
    { countryname: "Vanuatu", countrycode: "VU" },
    { countryname: "Vatican City", countrycode: "VA" },
    { countryname: "Venezuela", countrycode: "VE" },
    { countryname: "Vietnam", countrycode: "VN" },
    { countryname: "Yemen", countrycode: "YE" },
    { countryname: "Zambia", countrycode: "ZM" },
    { countryname: "Zimbabwe", countrycode: "ZW" }
  ];
  