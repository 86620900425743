import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { SubHeader } from "../components/SubHeader";
import { useMemo } from "react";

export const UserHome = () => {
  const settingData = JSON.parse(sessionStorage.getItem("settings")) || {
    image: "https://onboardify.tasc360.com/uploads/y22.png",
    site_bg: "#ffffff",
    button_bg: "#497ed8",
    banner_bg: "#497ed8",
    banner_content:
      "Hire an attitude, not just experience and qualification. Greg Savage.",
    header_bg: "#f7f7f7",
    head_title_color: "#497ed8",
  };

  const data = useMemo(() => {
    if (settingData.homePageSetting !== undefined) {
      return settingData.homePageSetting.map((item) => ({
        title: item.title,
        description: item.description,
        icon: item.icon_url,
        buttonText: item.btnText,
        navigateKey: item.navigateKey,
      }));
    } else {
      return [];
    }
  }, [settingData]);

  const navigate = useNavigate();

  const handleAdminRoute = (title) => {
    navigate(title);
  };

  return (
    <>
      <SubHeader />
      <div className="governify-option-list">
        {data.map((item) => {
          return (
            <div
              className="governify-option-list-repetitive"
              style={{ position: "relative", paddingBottom: "40px" }}
              key={item.navigateKey}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "90px",
                }}
              >
                <img
                  src={item.icon}
                  alt="No Preview"
                  style={{
                    width: "100px",
                    height: "100px",
                    objectFit: "contain",
                  }}
                />
              </div>
              <div className="governify-option-list-title font-family-hind fs-28 fw-700 mt-16 mb-16">
                {item.title}
              </div>
              <div
                className="governify-option-list-description font-family-hind fs-19 text-color-928f8f mb-16"
                style={{ minHeight: "114px", paddingBottom: "20px" }}
              >
                {item.description}
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  type="primary"
                  className="border-radius-10 fs-17 fw-600 h-40"
                  style={{
                    background: settingData.button_bg,
                    color: "#fff",
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                    position: "absolute",
                    bottom: "0px",
                    cursor: "pointer",
                  }}
                  onClick={() => handleAdminRoute(item.navigateKey)}
                >
                  <span>{item.buttonText}</span>
                  <span className="fs-16">
                    <i className="bi bi-arrow-right-circle-fill"></i>
                  </span>
                </Button>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};
